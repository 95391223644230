<template>
    <div>
        <!--탑메뉴-->
        <topbar-comp></topbar-comp>
        <left-bar-comp>
            <sports-left-bar-comp></sports-left-bar-comp>
        </left-bar-comp>

        <div class="content_section">
            <div class="content">
                <shop-links-comp></shop-links-comp>
                <div class="board_list">
                    <div class="logo_panel" style="padding: 20px">
                        <div class="logo">
                            <img src="../../assets/images/logo/oneplay/oneplay_logo.png">
                        </div>
                        <div class="ment line mt10">
                            <span style="color: #079e39">원플레이</span> 코인충전에 오신것을 환영합니다.
                        </div>
                        <div class="ment mt10" style="font-size: 12px;color: #c4c4c3">
                            <p>구입하신 코인은 원플레이 아이템샵에서 사용하실 수 있습니다</p>
                        </div>
                        <div class="reg_logo_panel">
                            <div class="recharge_hando_panel">
                                <div class="item">
                                    <div class="icon">
                                        <img src="../../assets/images/icon/item_icon_money.png" alt="">
                                    </div>
                                    <div class="txt">
                                        <p class="txt_ment">보유코인</p>
                                        <p class="txt_coin">{{$store.state.userInfo.cash|comma}} COIN</p>
                                    </div>
                                </div>
                                <div class="item">
                                    <div class="icon">
                                        <img src="../../assets/images/icon/item_icon_money.png" alt="">
                                    </div>
                                    <div class="txt">
                                        <p class="txt_ment">{{nowmonth}} 충전한도</p>
                                        <p class="txt_coin">{{$store.state.userInfo.rechargeHando|comma}} COIN</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <h4 class="pay_title">충전선택
                            <span style="font-size: 10px;font-weight: normal;margin-left: 10px">  충전할 금액을 선택하거나 직접 입력해주세요</span>
                        </h4>
                        <div class="select_cash_buttons_panel">
                            <div>
                                <button class="btn03" @click="changeMoney(3000,1)"
                                        :class="{'active_coin_button':activeCoinButton === 1}">3,000
                                </button>
                            </div>
                            <div>
                                <button class="btn03" @click="changeMoney(5000,2)"
                                        :class="{'active_coin_button':activeCoinButton === 2}">5,000
                                </button>
                            </div>
                            <div>
                                <button class="btn03" @click="changeMoney(10000,3)"
                                        :class="{'active_coin_button':activeCoinButton === 3}">10,000
                                </button>
                            </div>
                            <div>
                                <button class="btn03" @click="changeMoney(30000,4)"
                                        :class="{'active_coin_button':activeCoinButton === 4}">30,000
                                </button>
                            </div>
                            <div>
                                <button class="btn03" @click="changeMoney(50000,5)"
                                        :class="{'active_coin_button':activeCoinButton === 5}">50,000
                                </button>
                            </div>
                            <div>
                                <button class="btn03" @click="changeMoney(70000,6)"
                                        :class="{'active_coin_button':activeCoinButton === 6}">70,000
                                </button>
                            </div>
                            <div>
                                <button class="btn03" @click="changeMoney(100000,7)"
                                        :class="{'active_coin_button':activeCoinButton === 7}">100,000
                                </button>
                            </div>
                            <div>
                                <button class="btn03" @click="changeMoney(150000,8)"
                                        :class="{'active_coin_button':activeCoinButton === 8}">150,000
                                </button>
                            </div>
                            <div>
                                <button class="btn03" @click="changeMoney(200000,9)"
                                        :class="{'active_coin_button':activeCoinButton === 9}">200,000
                                </button>
                            </div>
                            <div>
                                <button class="btn03" @click="changeMoney(300000,10)"
                                        :class="{'active_coin_button':activeCoinButton === 10}">300,000
                                </button>
                            </div>
                            <div>
                                <button class="btn03" @click="changeMoney(500000,11)"
                                        :class="{'active_coin_button':activeCoinButton === 11}">500,000
                                </button>
                            </div>
                            <div>
                                <input type="text" v-model="applyCash.amount" placeholder="직접입력">
                            </div>
                        </div>


                        <h4 class="pay_title">결제방법 선택</h4>
                        <div class="check_pay_logo_panel">
                            <div class="item" @click="changePayType(sportsConst.RECHARGE_TYPE_MUTONGZANG)"
                                 :class="{'active_pay':activePay === sportsConst.RECHARGE_TYPE_MUTONGZANG}">
                                <img src="../../assets/images/icon/recharge_icon_1.png" alt="">
                                <span class="txt1">계좌이체</span>
                                <span class="txt2">이용약관 동의 후 은행 선택</span>
                            </div>
                            <div class="item" @click="changePayType(sportsConst.RECHARGE_TYPE_SHINYONG)"
                                 :class="{'active_pay':activePay === sportsConst.RECHARGE_TYPE_SHINYONG}">
                                <img src="../../assets/images/icon/recharge_icon_3.png" alt="">
                                <span class="txt1">신용카드</span>
                                <span class="txt2">카드사, 이용약관 등 전체동의 선택</span>
                            </div>
                            <div class="item" @click="changePayType(sportsConst.RECHARGE_TYPE_PHONE)"
                                 :class="{'active_pay':activePay === sportsConst.RECHARGE_TYPE_PHONE}">
                                <img src="../../assets/images/icon/recharge_icon_2.png" alt="">
                                <span class="txt1">휴대폰결제</span>
                                <span class="txt2">휴대폰번호, 주민번호 앞7자리 등 입력</span>
                            </div>
                            <div class="item" @click="changePayType(4)" :class="{'active_pay':activePay === 4}">
                                <img src="../../assets/images/icon/recharge_icon_4.png" alt="">
                                <span class="txt1">실시간이체</span>
                                <span class="txt2">인터넷 뱅킹과 동일한 보안방식 적용</span>
                            </div>
                        </div>


                        <h4 class="pay_title">결제정보
                            <span style="font-size: 10px;font-weight: normal;margin-left: 10px">  총 청구되는 금액과 충전되는 코인입니다</span>
                        </h4>
                        <div class="" v-if="$store.state.isLogin"
                             style="margin-top: 20px;background-color: #333333;box-sizing: border-box;padding: 20px;display: flex;justify-content: space-between;flex-wrap: nowrap">
                            <div style="width: 100%;margin-top: 20px">
                                <h3 style="width: 100%;text-align: right;padding-right: 20px">결제금액: <span
                                        v-if="applyCash.amount"
                                        style="color: #f4c600">{{applyCash.amount|comma}}</span>
                                </h3>
                                <p style="width: 100%;text-align: right;padding: 10px 10px;">은행:  (주)이한소프트 국민은행</p>
                                <p style="width: 100%;text-align: right;padding: 10px 10px;"> 계좌번호:
                                    0936-0101-059-648</p>

                            </div>
                        </div>
                        <div class="clearfix"
                             style="display:flex;flex-wrap:nowrap;justify-content: left;background-color: #515151;box-sizing: border-box;padding: 20px">
                            <div style="width: 60px"><img
                                    src="../../assets/images/icon/item_icon_warning.png" style="width: 50px;" alt="">
                            </div>
                            <div style="font-size: 10px;text-align: left;">
                                <p>환불은 결제일로부터 7일이내에 가능합니다.</p>
                                <p>환불은 고객센터 > 환불신청을 통해 접수하시면 신속하게 처리해 드리겠습니다.</p>
                                <p>환불 시에는 10% 를 제외한 나머지 금액이 환불되며 요청일로부터 3일 이내에 처리됩니다. (영업일 기준)</p>
                                <p style="color: #ff0000">구매한 캐쉬를 일부 소진한 경우 환불이 불가능합니다.</p>
                            </div>
                        </div>
                        <div style="margin-top: 20px">
                            <input type="checkbox" id="allowBuy" v-model="allowBuy"> <label for="allowBuy">위 구매조건 확인 및
                            결제진행에
                            동의합니다</label>
                        </div>
                        <div class="btn_center" style="width: 100%">
                            <button class="btn02" style="width: 50%;padding: 20px 0;margin-top: 20px"
                                    @click="recharge()">충전신청
                            </button>
                        </div>

                    </div>

                </div>
            </div>
        </div>
        <!-- right 메뉴 -->
        <right-bar-comp>
            <right-bar-banner-comp slot="rightBanner"></right-bar-banner-comp>
        </right-bar-comp>

        <foot-comp></foot-comp>
        <form name="form_shinyong" method="post" target="_blank">
            <input type="hidden" name="receiveUrl"
                   value="https://oneplay.games/api/niceid/pg_success">
            <input type="hidden" name="receiveType" value="url">
            <input type="hidden" name="productName" size="20"
                   :value="($root.$options.filters.comma(applyCash.amount)) + '코인'">
            <input type="hidden" name="orderTel" :value="shinyongInfomation.phone">
            <input type="hidden" name="orderUserName" :value="shinyongInfomation.realName">
            <input type="hidden" name="payAmount" size="20" :value="applyCash.amount">
            <input type="hidden" name="memManageNo" :value="shinyongInfomation.memManageNo">
            <input type="hidden" name="comparam" size="20" :value="shinyongInfomation.comparam">
            <input type="hidden" name="comMemID" size="20" :value="shinyongInfomation.comMemID">
            <input type="hidden" name="comOrderNo" size="20" :value="shinyongInfomation.comOrderNo">
        </form>


    </div>

</template>

<script>
    import SubTitle from "../../components/SubTitle";
    import RightBarComp from "../../components/RightBarComp";
    import {
        deleteApply,
        getApplyCashList,
        getHando,
        getRechargeInfomation,
        rechargeCash
    } from "../../network/userRequest";
    import sportsConst from "../../common/sportsConst";
    import LeftBarComp from "../../components/LeftBarComp";
    import FootComp from "../../components/FootComp";
    import TopbarComp from "../../components/TopbarComp";
    import RightBarBannerComp from "../../components/RightBarBannerComp";
    import {RECEIVE_SPORTS_CART_ITEM_DEL_ALL} from "../../store/mutation-types";
    import SportsLeftBarComp from "../../components/sports/SportsLeftBarComp";
    import ShopLinksComp from "../../components/links/ShopLinksComp";

    export default {
        name: "Recharge",
        components: {
            ShopLinksComp,
            SportsLeftBarComp,
            RightBarBannerComp, TopbarComp, FootComp, LeftBarComp, RightBarComp, SubTitle
        },
        data() {
            return {
                sportsConst,
                applyCash: {
                    amount: null,
                    rechargeType: 0,
                    name: '',
                    ordertel: '',
                },
                search: {applyType: sportsConst.APPLY_TYPE_RECHARGE},
                rechargeList: [],
                isSampleSite: false,
                activePay: 0,
                activeCoinButton: 0,
                allowBuy: false,
                nowmonth: this.$moment().format('YYYY년 MM월'),
                showShinyongDiv: false,
                shinyongInfomation: null
            }
        },
        methods: {
            resetActive() {
                this.activePay = 0
                this.applyCash.name = ''
                this.applyCash.ordertel = ''
            },
            okShinyong() {
                if (document.form_shinyong.orderUserName == '') {
                    alert('이름을 입력하세요')
                    return false;
                }
                if (document.form_shinyong.ordertel == '') {
                    alert('휴대폰번호를 입력하세요')
                    return false;
                }

            },
            changePayType(type) {
                if (this.applyCash.amount == null || this.applyCash.amount <= 0) {
                    this.$swal({
                        title: '충전하실 금액을 선택 또는 입력하세요',
                        type: 'error',
                        showCancelButton: false,
                        showConfirmButton: true
                    })
                    return false;
                }
                if (type > 1) {
                    this.$swal({
                        title: '서비스 준비중입니다',
                        type: 'info',
                        showCancelButton: false,
                        showConfirmButton: true
                    })
                    return false;
                }
                this.activePay = type;
            },
            changeMoney(cash, ab) {
                this.activeCoinButton = ab;
                if (cash === 0) {
                    this.applyCash.amount = 0;
                } else {
                    this.applyCash.amount = cash;
                }
            },
            recharge() {

                if (this.activePay === 0) {
                    this.$swal({
                        title: '결제방법을 선택하세요',
                        type: 'warning',
                        showCancelButton: false,
                        showConfirmButton: true
                    })
                    return false;
                }
                if (!this.allowBuy) {
                    this.$swal({
                        title: '구매조건확인 및 동의가 필요합니다',
                        type: 'warning',
                        showCancelButton: false,
                        showConfirmButton: true
                    })
                    return false;
                }

                //신용카드결제
                if (this.activePay === 2) {
                    getHando().then(res=>{
                        if(res.data.success){
                            let hando =res.data.data
                            if(this.applyCash.amount > hando){
                                this.$swal({
                                    title: '월 한도를 초과하였습니다',
                                    type: 'error',
                                    showCancelButton: false,
                                    showConfirmButton: true
                                })
                                return false
                            }
                            let url = ""
                            if (this.browser().mobile) {
                                url = "https://pairingpayments.com/extlink/cert_requestpay_m_utf8.asp"
                                window.open('', 'popupChk', 'width=375, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no');
                            } else {
                                url = "https://pairingpayments.com/extlink/cert_requestpay_utf8.asp"
                                window.open('', 'popupChk', 'width=1024, height=800, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no');
                            }
                            document.form_shinyong.action = url;
                            document.form_shinyong.target = "popupChk";
                            document.form_shinyong.submit();
                        }
                    })
                    return false
                }

                //무통장결제
                if(this.activePay === 1){

                    this.$swal({
                        title: this.applyCash.amount + '원 충전신청 하시겠습니까?',
                        type: 'question',
                        confirmButtonText: ' 예 ',
                        cancelButtonText: '아니오'
                    }).then((res) => {
                        this.betInfo = {selectedVals: []}
                        if (res.value) {
                            rechargeCash(this.applyCash).then(res => {
                                let title = "";
                                if (res.data.success) {
                                    let ti = this.$moment().add(2, 'day').format("YYYY-MM-DD HH:mm")
                                    title = "충전신청이 완료되었습니다. "
                                    title += "\r\n " + ti + " 까지 "
                                    title += "\r\n " + " 무통장으로 입금해 주시기 바랍니다"
                                    title += "\r\n " + " 입금 확인이 안될시 자동 취소처리 됩니다"
                                    title += "\r\n " + " 감사합니다."
                                    this.$swal({
                                        title: title,
                                        type: 'success',
                                        showCancelButton: false,
                                        showConfirmButton: true
                                    })
                                    this.initRechargeList()
                                } else {
                                    this.$swal({
                                        title: res.data.msg,
                                        type: 'error',
                                        showCancelButton: false,
                                        showConfirmButton: true
                                    })
                                }
                            })
                        }
                    })
                }




            },
            refreshData() {
                this.$bus.$emit('applyRequestSuccess')
            },
            deleteById(id) {
                this.$swal({
                    title: '삭제하시겠습니까?',
                    type: 'question',
                    confirmButtonText: ' 예 ',
                    cancelButtonText: '아니오'
                }).then((res) => {
                    if (res.value) {
                        deleteApply(id).then(res => {
                            if (res.data.success) {
                                this.initRechargeList()
                            } else {
                                this.$swal({
                                    title: res.data.msg,
                                    type: 'error',
                                    showCancelButton: false,
                                    showConfirmButton: true
                                })
                            }
                        })
                    }
                })
            },
            browser() {
                let u = navigator.userAgent
                let app = navigator.appVersion
                return {         //移动终端浏览器版本信息
                    trident: u.indexOf('Trident') > -1, //IE
                    presto: u.indexOf('Presto') > -1, //opera
                    webKit: u.indexOf('AppleWebKit') > -1, //Apple,Google
                    gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') == -1, //FireFox
                    mobile: !!u.match(/AppleWebKit.*Mobile.*/), //
                    ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //
                    android: u.indexOf('Android') > -1 || u.indexOf('Linux') > -1, //
                    iPhone: u.indexOf('iPhone') > -1, //
                    iPad: u.indexOf('iPad') > -1, //
                    webApp: u.indexOf('Safari') == -1//
                }
            }
        },
        created() {
            if (!this.$store.state.isLogin) {
                this.$swal({
                    title: '로그인이 필요한 서비스 입니다.',
                    type: 'warning',
                    showCancelButton: false,
                    showConfirmButton: true
                })
                this.$router.push('/login')
                return false;
            }

            getRechargeInfomation().then(res => {
                if (res.data.success) {
                    this.shinyongInfomation = res.data.data
                    console.log(this.shinyongInfomation)
                }
            })

        },
        mounted() {

        }
    }
</script>

<style scoped>
    @import url("../../assets/css/reex.css");


    .shinyong {
        flex-wrap: wrap;
        width: 100%;
        height: 1000px;
        left: 0;
        top: 70px;
        background-color: #1a4050;
        opacity: .95;
        position: fixed;
        transition: 500ms all;
    }

    .shinyong .sytitle {
        width: 100%;
        text-align: center;
        box-sizing: border-box;
        padding: 10px 0;
        font-size: 28px;
        color: #f19f09;
    }

    .shinyong .syamount {
        width: 100%;
        text-align: center;
        box-sizing: border-box;
        padding: 10px 0;
        font-size: 28px;
    }

    .shinyong .syinput {
        width: 100%;
        text-align: center;
        box-sizing: border-box;
        padding: 10px 0;
    }

    .shinyong .syinput input {
        text-align: center;
        width: 150px;
        height: 28px;
        color: #1a1a1a;
    }
</style>